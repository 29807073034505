import { Box } from "@twilio-paste/core/box"
import { Label } from "@twilio-paste/core/label";
import { TextArea } from "@twilio-paste/core/textarea";
import { Text } from "@twilio-paste/core/text";
import { fieldStyles } from "../styles/PreEngagementFormPhase.styles";
import { useDispatch, useSelector } from "react-redux";
import { createSelector } from "reselect";
import type { AppState } from "../../store/definitions";
import { updatePreEngagementData } from "../../store/actions/genericActions";
import { ThunkDispatch } from "redux-thunk";
import { ChatReducer } from "../../store/chat.reducer";
import { SessionReducer } from "../../store/session.reducer";
import { AnyAction } from "redux";
import type { WebchatConfigForm } from "@ciptex/conversation-sdk";
import { useValidation } from "../../providers/ValidationContext";

type Field = NonNullable<WebchatConfigForm['fields']>[number];

interface TextareaFieldProps {
    field: Field & { type: "textarea" };
}

const textareaFieldSelector = createSelector(
    (state: AppState) => state,
    (state) => ( {preEngagementData: state.session.preEngagementData })
);

export const TextareaField = ({ field }: TextareaFieldProps) => {

    const { preEngagementData } = useSelector(textareaFieldSelector) || {};
    const dispatch = useDispatch<ThunkDispatch<ChatReducer | SessionReducer, AnyAction, AnyAction>>();
    const { validateField, errors } = useValidation();

    return (
    <Box {...fieldStyles}>
        <Label htmlFor={field.id}>{field.label}</Label>
        <TextArea
            element="DEFAULT_INPUT"
            placeholder={field.placeholder}
            name={field.id}
            id={field.id}
            maxLength={field.maxLength}
            data-test={`pre-engagement-chat-form-${field.id}-input`}
            value={preEngagementData?.[field.id] ?? ""}
            onChange={(e) => dispatch(updatePreEngagementData({ [field.id]: e.target.value }))}
            onBlur={(e) => validateField(field.id, e.target.value)}
            autoComplete={field.id}
            required={field.required}
        />
        {errors[field.id] && (
            <Text color="colorTextError" as={"p"}>
                {errors[field.id]}
            </Text>
        )}
    </Box>);
}