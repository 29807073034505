import { Box } from "@twilio-paste/core/box"
import { fieldStyles } from "../styles/PreEngagementFormPhase.styles";
import { useDispatch, useSelector } from "react-redux";
import { createSelector } from "reselect";
import { Radio, RadioGroup } from "@twilio-paste/core/radio-group"
import { updatePreEngagementData } from "../../store/actions/genericActions";
import { ThunkDispatch } from "redux-thunk";
import { ChatReducer } from "../../store/chat.reducer";
import { SessionReducer } from "../../store/session.reducer";
import { useValidation } from "../../providers/ValidationContext";
import { AnyAction } from "redux";
import { useCallback } from "react";

import type { WebchatConfigForm } from "@ciptex/conversation-sdk";
import type { AppState } from "../../store/definitions";
type Field = NonNullable<WebchatConfigForm["fields"]>[number];

interface RadioFieldProps {
    field: Field & { type: "radio" };
}

const radioFieldSelector = createSelector(
    (state: AppState) => state,
    (state) => ({ preEngagementData: state.session.preEngagementData })
);

export const RadioField = ({ field }: RadioFieldProps) => {
    
    const { preEngagementData } = useSelector(radioFieldSelector) || {};
    const dispatch = useDispatch<ThunkDispatch<ChatReducer | SessionReducer, unknown, AnyAction>>();
    const { validateField, errors } = useValidation();

    const handleRadioChange = useCallback((value: string) => {
        dispatch(updatePreEngagementData({ [field.id]: value }));
        setTimeout(() => validateField(field.id, value), 0);
    }, [dispatch, field.id, validateField]);

    const fieldError = errors[field.id];

    return (
        <Box {...fieldStyles}>
            <RadioGroup
                legend={field.label}
                element="DEFAULT_RADIO_GROUP"
                name={field.id}
                id={field.id}
                data-test={`pre-engagement-chat-form-${field.id}-input`}
                value={preEngagementData?.[field.id] ?? ""}
                onChange={handleRadioChange}
                errorText={fieldError}
            >
                {field.options?.map((option) => (
                    <Radio
                        key={`${field.id}-${option.value}`}
                        value={option.value}
                        data-test={`pre-engagement-chat-form-${field.id}-input-${option.value}`}
                    hasError={!!fieldError}

                    >
                        {option.label}
                    </Radio>
                )) ?? 
                <Radio value="">
                    No options available
                </Radio>
                }
            </RadioGroup>
        </Box>
    );
}