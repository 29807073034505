import React, { createContext, useContext, useState, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import type { AppState } from '../store/definitions';

interface ValidationContextType {
  errors: Record<string, string>;
  validateField: (field: string, value: string) => string;
  isValid: () => boolean;
  setErrors: React.Dispatch<React.SetStateAction<Record<string, string>>>;
}

const ValidationContext = createContext<ValidationContextType | undefined>(undefined);

const validationSelector = createSelector(
  (state: AppState) => state,
  (state) => ({
    preEngagementData: state.session.preEngagementData,
    preEngagementForm: state.config.preEngagementForm,
  })
);

export const ValidationProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { preEngagementData, preEngagementForm } = useSelector(validationSelector) || {};
  const [errors, setErrors] = useState<Record<string, string>>({});

  const validateField = useCallback((field: string, value: string) => {
    const formField = preEngagementForm.fields?.find(formField => formField.id === field);
    let error = "";
    
    if (formField?.required) {
      switch(formField.type) {
        case "checkbox":
          error = !value || value === "" ? "Please select at least one option" : "";
          break;
        case "radio":
          error = !value || value === "" ? "Please select an option" : "";
          break;
        case "select":
          error = !value || value === "" ? "Please select an option" : "";
          break;
        default:
          error = value.trim() === "" ? "This field is required" : "";
      }
    }

    setErrors(prev => ({
      ...prev,
      [field]: error
    }));

    return error;
  }, [preEngagementForm.fields]);

  const isValid = useCallback(() => {
    if (!preEngagementForm.fields) return false;

    const newErrors: Record<string, string> = {};
    let isFormValid = true;
    
    preEngagementForm.fields.forEach(field => {
      const value = preEngagementData?.[field.id] ?? "";
      
      if (field.required) {
        let error = "";
        switch(field.type) {
          case "checkbox":
            error = !value || value === "" ? "Please select at least one option" : "";
            break;
          case "radio":
            error = !value || value === "" ? "Please select an option" : "";
            break;
          case "select":
            error = !value || value === "" ? "Please select an option" : "";
            break;
          default:
            error = value.trim() === "" ? "This field is required" : "";
        }
        
        newErrors[field.id] = error;
        
        if (error) {
          isFormValid = false;
        }
      }
    });

    setErrors(newErrors);
    
    console.log('Form validation - Errors:', newErrors);
    console.log('Form validation - Valid:', isFormValid);
    
    return isFormValid;
  }, [preEngagementForm.fields, preEngagementData]);

  return (
    <ValidationContext.Provider value={{ errors, validateField, isValid, setErrors }}>
      {children}
    </ValidationContext.Provider>
  );
};

export const useValidation = () => {
  const context = useContext(ValidationContext);
  if (!context) {
    throw new Error('useValidation must be used within a ValidationProvider');
  }
  return context;
};