import { Box } from "@twilio-paste/core/box";
import { AnimatePresence, Variants } from "framer-motion";
import { useCallback, useEffect, useRef, useState, useMemo } from "react";
import { isMobile } from "react-device-detect";
import Draggable from "react-draggable";
import { useSelector } from "react-redux";
import { createSelector } from "reselect";

import type { DraggableData, DraggableEvent } from "react-draggable";
import type { ResizeCallbackData } from "react-resizable";

import { AppState, EngagementPhase } from "../store/definitions";
import { EntryPoint } from "./EntryPoint";
import { LoadingPhase } from "./LoadingPhase";
import { BotCanvasPhase } from "./phases/BotCanvasPhase";
import { MessagingCanvasPhase } from "./phases/MessagingCanvasPhase";
import { PreEngagementFormPhase } from "./phases/PreEngagementFormPhase";
import { MotionInnerContainer, MotionOuterContainer } from "./styles/RootContainer.styles";
import { ResizableContainer } from "./ResizableContainer";
import { ChannelSelectionPhase } from "./phases/ChannelSelectionPhase";

const duration: number = 0.2;

const containerVariants: Variants = {
    hidden: {
        opacity: 0,
        transition: { duration: duration, ease: "easeInOut" }
    },
    visible: {
        opacity: 1,
        transition: { duration: duration, ease: "easeInOut" }
    },
    exit: {
        opacity: 0,
        transition: { duration: duration, ease: "easeInOut" }
    }
};

const innerContainerVariants = {
    hidden: {
        opacity: 0,
        scale: 0.95,
        transition: { duration: duration, ease: "easeInOut" }
    },
    visible: {
        opacity: 1,
        scale: 1,
        transition: { duration: duration, ease: "easeInOut" }
    },
    exit: {
        opacity: 0,
        scale: 0.95,
        transition: { duration: duration, ease: "easeInOut" }
    }
};

const getPhaseComponent = (phase: EngagementPhase) => {
    switch (phase) {
        case EngagementPhase.Loading:
            return <LoadingPhase />;
        case EngagementPhase.BotCanvas:
            return <BotCanvasPhase />;
        case EngagementPhase.MessagingCanvas:
            return <MessagingCanvasPhase />;
        case EngagementPhase.PreEngagementForm:
            return <PreEngagementFormPhase />;
        case EngagementPhase.ChannelSelection:
        default:
            return <ChannelSelectionPhase />;
    }
};

const rootContainerSelector = createSelector(
    (state: AppState) => state,
    (state) => ({
        currentPhase: state.session.currentPhase,
        expanded: state.session.expanded,
        cssOverrides: state.config.theme.cssOverrides,
        enableDragFlag: state.config.flags?.dragEnabled,
        enableResizeFlag: state.config.flags?.resizeEnabled
    })
);

export function RootContainer() {
    const { currentPhase, expanded, cssOverrides, enableDragFlag, enableResizeFlag } = useSelector(rootContainerSelector);
    /*
    containerRef is null to avoid 'ReactDOM.findDOMNode() is deprecated' error.
    Link: https://www.npmjs.com/package/react-draggable?activeTab=readme
    */
    const containerRef = useRef(null);

    const initialPosition = useMemo(() => ({
        x: 0,
        y: enableResizeFlag ? -15 : 0
    }), [enableResizeFlag]);

    const [position, setPosition] = useState(initialPosition);

    const getContainerHeight = useCallback(
        (overrideHeight?: string): string => {
            if (isMobile) {
                return "100%";
            }
            return overrideHeight || "550px";
        },
        [isMobile]
    );

    const getContainerWidth = useCallback(
        (overrideWidth?: string): string => {
            if (isMobile) {
                return "100%";
            }
            return overrideWidth || "400px";
        },
        [isMobile]
    );

    const defaultSize = useMemo(() => ({
        width: cssOverrides?.container?.width 
        ? configSizeFormatter(cssOverrides?.container?.width) 
        : configSizeFormatter(getContainerWidth()), 
        height: cssOverrides?.container?.height 
        ? configSizeFormatter(cssOverrides?.container?.height) 
        : configSizeFormatter(getContainerHeight())
    }), [cssOverrides]);

    const [size, setSize] = useState({...defaultSize});

    useEffect(() => {
        const handleWindowResize = () => {
            setPosition(initialPosition);
            setSize({  
                width: defaultSize.width,
                height: defaultSize.height
            });
        };

        window.addEventListener("resize", handleWindowResize);
        return () => window.removeEventListener("resize", handleWindowResize);
    }, []);

    const handleStop = useCallback(
        (e: DraggableEvent, data: DraggableData) => {
            setPosition({ x: data.x, y: data.y });
        },
        [setPosition]
    );

    const onChatResize = useCallback(
        (event: React.SyntheticEvent, { size }: ResizeCallbackData) => {
            setSize({ width: size.width, height: size.height });
        },
        [setSize]
    );

    function configSizeFormatter(value: string = "450px"): number {
        const numericPart = parseFloat(value);
        return !isNaN(numericPart) ? numericPart : 450;
    }

    const content = (
        <MotionOuterContainer
            variants={containerVariants}
            initial="hidden"
            animate="visible"
            exit="exit"
            ref={containerRef}
            style={{
                visibility: expanded ? "visible" : "hidden"
            }}
        >
            <AnimatePresence>
               {expanded && (
                <ResizableContainer
                    enableDragFlag={enableDragFlag}
                    enableResizeFlag={enableResizeFlag}
                    size={size}
                    onChatResize={onChatResize}
                    cssOverrides={cssOverrides}
                    getContainerHeight={getContainerHeight}
                    getContainerWidth={getContainerWidth}
                    configSizeFormatter={configSizeFormatter}
                >
                      <MotionInnerContainer
                                data-test="root-container"
                                variants={innerContainerVariants}
                                initial="hidden"
                                animate="visible"
                                exit="exit"
                                style={{
                                    height: "100%",
                                    width: "100%"
                                }}
                            >
                        {getPhaseComponent(currentPhase)}
                    </MotionInnerContainer>
                </ResizableContainer>
            )}
            </AnimatePresence>
        </MotionOuterContainer>
    );
    return (
        <Box>
            {!isMobile && enableDragFlag ? (
                <Draggable
                    bounds="body"
                    handle=".draggable-handle"
                    position={position}
                    onStop={handleStop}
                    nodeRef={containerRef}
                    cancel=".resize-handle"
                >
                    {content}
                </Draggable>
            ) : (
                content
            )}
            <EntryPoint />
        </Box>
    );
}
