// Config reducer actions
export const ACTION_LOAD_CONFIG = "ACTION_LOAD_CONFIG";

// Chat reducer actions
export const ACTION_CHANGE_EXPANDED_STATUS = "ACTION_CHANGE_EXPANDED_STATUS";
export const ACTION_CHANGE_ENGAGEMENT_PHASE = "ACTION_CHANGE_ENGAGEMENT_PHASE";
export const ACTION_START_SESSION = "ACTION_START_SESSION";
export const ACTION_UPDATE_PARTICIPANT = "ACTION_UPDATE_PARTICIPANT";
export const ACTION_ADD_PARTICIPANT = "ACTION_ADD_PARTICIPANT";
export const ACTION_REMOVE_PARTICIPANT = "ACTION_REMOVE_PARTICIPANT";
export const ACTION_UPDATE_MESSAGE = "ACTION_UPDATE_MESSAGE";
export const ACTION_ADD_MESSAGE = "ACTION_ADD_MESSAGE";
export const ACTION_REMOVE_MESSAGE = "ACTION_REMOVE_MESSAGE";
export const ACTION_ADD_MULTIPLE_MESSAGES = "ACTION_ADD_MULTIPLE_MESSAGES";
export const ACTION_ATTACH_FILES = "ACTION_ATTACH_FILES";
export const ACTION_DETACH_FILES = "ACTION_DETACH_FILES";
export const ACTION_UPDATE_CONVERSATION_STATE = "ACTION_UPDATE_CONVERSATION_STATE";
export const ACTION_RESET_CHAT_STATE = "ACTION_RESET_CHAT_STATE";
export const ACTION_UPDATE_SESSION_DATA = "ACTION_UPDATE_SESSION_DATA";
export const ACTION_UPDATE_PRE_ENGAGEMENT_DATA = "ACTION_UPDATE_PRE_ENGAGEMENT_DATA";
export const ACTION_RESET_PRE_ENGAGEMENT_DATA = "ACTION_CLEAR_PRE_ENGAGEMENT_DATA";

// Notification reducer actions
export const ACTION_ADD_NOTIFICATION = "ACTION_ADD_NOTIFICATION";
export const ACTION_REMOVE_NOTIFICATION = "ACTION_REMOVE_NOTIFICATION";

// Session Actions
export const ACTION_END_SESSION = "ACTION_END_SESSION";

// Bot Actions
export const ACTION_START_BOT_SESSION = "ACTION_START_BOT_SESSION";
export const ACTION_SET_ACTIVE_BOT_STEP_STATUS = "ACTION_SET_ACTIVE_BOT_STEP_STATUS";
export const ACTION_SET_STORE_RESPONSE = "ACTION_SET_STORE_RESPONSE";
export const ACTION_SET_SHOW_MESSAGE_INPUT = "ACTION_SET_SHOW_MESSAGE_INPUT";
export const ACTION_SET_ACTIVE_BOT_STEP = "ACTION_SET_ACTIVE_BOT_STEP";
export const ACTION_RESET_BOT_DATA = "ACTION_RESET_BOT_DATA";
export const ACTION_SET_IS_ESCALATED = "ACTION_SET_IS_ESCALATED";
export const ACTION_UPDATE_TASK_ATTRIBUTES = "ACTION_UPDATE_TASK_ATTRIBUTES";
export const ACTION_UPDATE_CONVERSATION_LABELS = "ACTION_UPDATE_CONVERSATION_LABELS";
export const ACTION_SET_BOT_CONFIG = "ACTION_SET_BOT_CONFIG";
export const ACTION_INCREMENT_STORE_RESPONSE_ATTRIBUTE_INDEX = "ACTION_INCREMENT_STORE_RESPONSE_ATTRIBUTE_INDEX";
export const ACTION_SET_IS_REDIRECTING = "ACTION_SET_IS_REDIRECTING";
export const ACTION_SET_PENDING_FLOW = "ACTION_SET_PENDING_FLOW";
