import { Box } from "@twilio-paste/core/box";
import { Button } from "@twilio-paste/core/button";
import { Text } from "@twilio-paste/core/text";
import { FormEvent, useEffect, useState, useRef, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AnyAction } from "redux";

import type { ThunkDispatch } from "redux-thunk";
import type { ChatReducer } from "../../store/chat.reducer";
import { type AppState, type PreEngagementData, EngagementPhase } from "../../store/definitions";
import type { SessionReducer } from "../../store/session.reducer";
import type { WebchatConfigForm } from "@ciptex/conversation-sdk";

import { useValidation, ValidationProvider } from "../../providers/ValidationContext";
import { createSelector } from "reselect";
import { notifications } from "../../notifications";
import { sessionDataHandler } from "../../sessionDataHandler";
import { addNotification, changeEngagementPhase } from "../../store/actions/genericActions";
import { initBotSession, initSession } from "../../store/actions/initActions";
import { Footer } from "../Footer";
import { Header } from "../Header";
import { NotificationBar } from "../NotificationBar";
import { formStyles, introStyles, titleStyles } from "../styles/PreEngagementFormPhase.styles";

import { CheckboxField } from "../preEngagementFormFields/CheckboxField";
import { RadioField } from "../preEngagementFormFields/RadioField";
import { SelectField } from "../preEngagementFormFields/SelectField";
import { TextareaField } from "../preEngagementFormFields/TextareaField";
import { DefaultField } from "../preEngagementFormFields/DefaultField";

const preEngagementFormPhaseSelector = createSelector(
    (state: AppState) => state,
    (state) => ({
        preEngagementData: state.session.preEngagementData,
        twilioTaskQueueSid: state.config.twilio.taskQueueSid,
        twilioWorkflowSid: state.config.twilio.workflowSid,
        isBotConversation: state.config.bot.useBots ?? false,
        studioFlowSid: state.config.twilio.studioFlowSid,
        preEngagementForm: state.config.preEngagementForm,
        preEngagementFormHeader: state.config.theme.content?.preEngagementForm?.header,
        preEngagementFormBody: state.config.theme.content?.preEngagementForm?.body,
        preEngagementFormButtonText: state.config.theme.content?.preEngagementForm?.buttonText,
        cssOverrides: state.config.theme.cssOverrides,
        startButtonEnabled: state.config.flags.startButtonEnabled
    })
);

type FormField = NonNullable<WebchatConfigForm['fields']>[number];

export const PreEngagementFormPhaseContent = () => {
    const { 
        preEngagementData,
        twilioTaskQueueSid, 
        twilioWorkflowSid, 
        isBotConversation, 
        studioFlowSid,
        preEngagementForm,
        preEngagementFormHeader,
        preEngagementFormBody,
        preEngagementFormButtonText,
        cssOverrides,
        startButtonEnabled
    } = useSelector(preEngagementFormPhaseSelector) || {};
    const dispatch = useDispatch<ThunkDispatch<ChatReducer | SessionReducer, unknown, AnyAction>>();
    const [isScrollable, setIsScrollable] = useState<boolean>(false);
    const formRef = useRef<HTMLFormElement>(null);
    const { isValid } = useValidation();

    const defaults =  {
        header: "Hi there!",
        body: "We're here to help. Please give us some info to get started.",
        buttonText: "Start chat"
    };

    const handleScroll = useCallback(() => {
        if (formRef.current) {
            const { scrollTop, scrollHeight, clientHeight } = formRef.current;
            setIsScrollable(scrollTop + clientHeight < scrollHeight);
        }
    }, [setIsScrollable]);

    useEffect(() => {
        const form = formRef.current;
        if (form) {
            form.addEventListener("scroll", handleScroll);
            handleScroll();
        }

        return () => form?.removeEventListener("scroll" ,handleScroll)
    }, [])

    const handleSubmit = async (e: FormEvent) => {
        e.preventDefault();
        if (!isValid()) {
            return;
        }
        dispatch(changeEngagementPhase({ phase: EngagementPhase.Loading }));
        try {
            const currentURL = window.location.href;
            const truncatedURL = currentURL.length > 75 
            ? `${currentURL.substring(0, 72)}...` 
            : currentURL;

            const formData = {
                ...preEngagementData,
                originURL: truncatedURL
            } as PreEngagementData;

            const { token, conversationSid } = await sessionDataHandler.fetchAndStoreNewSession({
                email: preEngagementData?.email,
                phoneNumber: preEngagementData?.phone,
                twilioTaskQueueSid,
                twilioWorkflowSid,
                isBotConversation,
                studioFlowSid,
                // FormData is passed into the Task's info tab
                formData // Use all data from Redux store
            });
            if (isBotConversation) {
                dispatch(initBotSession({ token, conversationSid }));
            } else {
                dispatch(initSession({ token, conversationSid, studioFlowSid }));
            }
        } catch (err) {
            dispatch(addNotification(notifications.failedToInitSessionNotification((err as Error).message)));
            dispatch(changeEngagementPhase({ phase: EngagementPhase.PreEngagementForm }));
        }
    };

    useEffect(() => {
        const preEngagementFormSubmitButton = document.getElementById("pre-engagement-submit-button");
        if (!preEngagementFormSubmitButton) return;

        const submitOverrides: Record<string, string> = cssOverrides?.preEngagementForm?.submitButton;

        for (const style in submitOverrides) {
            /* @ts-expect-error  style is pulled from config */
            preEngagementFormSubmitButton.style[style] = submitOverrides[style];
        };
    }, []);
    
    const isTextareaField = (field: FormField): field is FormField & { type: "textarea" } => 
        field.type === "textarea";

    const isSelectField = (field: FormField): field is FormField & { type: "select" } => 
        field.type === "select";
    
    const isRadioField = (field: FormField): field is FormField & { type: "radio" } => 
        field.type === "radio";
    
    const isCheckboxField = (field: FormField): field is FormField & { type: "checkbox" } => 
        field.type === "checkbox";

    return (
        <>
            <Header />
            <NotificationBar />
            <Box 
                as="form" 
                data-test="pre-engagement-chat-form" 
                onSubmit={handleSubmit} 
                {...formStyles} 
                ref={formRef} 
                style={{ 
                    boxShadow: isScrollable ? "inset 0 -4px 5px rgba(0, 0, 0, 0.15)" : "inset 0 -4px 0 rgba(0, 0, 0, 0)",
                    transition: "box-shadow 0.3s ease-in-out",
                    zIndex: 1
                    }}
                >
            {!(preEngagementFormHeader === null || preEngagementFormHeader === "") 
            && <Text {...titleStyles} as="h3">
                    {preEngagementFormHeader !== undefined
                        ? preEngagementFormHeader
                        : defaults.header}
                </Text>
                }
                <Text {...introStyles} as="p">
                    {preEngagementFormBody ?? defaults.body}
                </Text>
                <Box display="flex" flexDirection="column">
                    {preEngagementForm.fields?.map((field) => {
                        if (isTextareaField(field)) {
                            return (
                                <TextareaField 
                                    key={`pre-engagement-form-${field.id}`}
                                    field={field}
                                />
                            );
                        }
                        if (isSelectField(field)) {
                            return (
                                <SelectField 
                                    key={`pre-engagement-form-${field.id}`}
                                    field={field}
                                />
                            );
                        }
                        if (isRadioField(field)) {
                            return (
                                <RadioField 
                                    field={field}
                                    key={`pre-engagement-form-${field.id}`}
                                />
                            );
                        }
                        if (isCheckboxField(field)) {
                            return (
                                <CheckboxField 
                                    key={`pre-engagement-form-${field.id}`}
                                    field={field}
                                />
                            );
                        }
                        return (
                            <DefaultField 
                                key={`pre-engagement-form-${field.id}`}
                                field={field}
                            />
                        );
                    })}
                    {startButtonEnabled === false ? null : 
                    <Button 
                        id="pre-engagement-submit-button"
                        variant="primary" 
                        type="submit" 
                        data-test="pre-engagement-start-chat-button"
                    >
                        {preEngagementFormButtonText ?? defaults.buttonText}
                    </Button>
                    }
                        </Box>
                </Box>
            <Footer />
        </>
    );
};
export const PreEngagementFormPhase = () => {
    return (
        <ValidationProvider>
            <PreEngagementFormPhaseContent />
        </ValidationProvider>
    );
};