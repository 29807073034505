import styled from "@emotion/styled";
import { motion } from "framer-motion";
import { isMobile } from "react-device-detect";

export const MotionOuterContainer = styled(motion.div)`
    position: fixed;
    bottom: ${isMobile ? "0" : "65px"};
    right: ${isMobile ? "0" : "15px"};
    left: ${isMobile ? "0" : "auto"};
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    max-width: ${isMobile ? "100%" : "700px"};
    width: ${isMobile ? "100%" : "auto"};
    height: ${isMobile ? "100%" : "auto"};
`;

export const MotionInnerContainer = styled(motion.div)`
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    width: ${isMobile ? "100%" : "450px"};
    max-width: 100%;
    height: ${isMobile ? "100%" : "550px"};
    max-height: ${isMobile ? "100%" : "80vh"};
    margin-bottom: ${isMobile ? "0" : "16px"};
    border-radius: ${isMobile ? "0" : "8px"};
    background-color: #ffffff;
`;

export const resizeHandleStyles: React.CSSProperties = {
    position: "absolute",
    left: 0,
    top: 0,
    width: "100%",
    height: "1px",
    justifyContent: "center",
    alignItems: "center",
    cursor: "ns-resize",
    borderRadius: "0 0 4px 0",
    paddingTop: "5px",
    zIndex: 999
};

