// faviconUtils.ts
export class FaviconNotification {
    private originalFavicon: HTMLLinkElement | null = null;
    private canvas: HTMLCanvasElement;
    private context: CanvasRenderingContext2D;

    constructor() {
        // creates an off-screen canvas to modify favicon. uses standard favicon size
        this.canvas = document.createElement('canvas');
        this.canvas.width = 32;
        this.canvas.height = 32;
        const ctx = this.canvas.getContext('2d');
        if (!ctx) throw new Error('Could not get canvas context');
        this.context = ctx;
    }

    public init() {
        this.originalFavicon = document.querySelector("link[rel='shortcut icon']");
        if (!this.originalFavicon) {
            this.originalFavicon = document.querySelector("link[rel='icon']");
        }
    }

    public async addNotificationDot() {
        if (!this.originalFavicon) return;

        const img = new Image();
        img.src = this.originalFavicon.href;

        try {
            await new Promise((resolve, reject) => {
                img.onload = resolve;
                img.onerror = reject;
            });
        } catch (e) {
            console.error('Failed to load favicon image:', e);
            return;
        }

        this.context.clearRect(0, 0, this.canvas.width, this.canvas.height);

        this.context.drawImage(img, 0, 0, 32, 32);

        this.context.beginPath();
        this.context.arc(24, 8, 8, 0, 2 * Math.PI);
        this.context.fillStyle = 'red';
        this.context.fill();

        const newFavicon = document.createElement('link');
        newFavicon.rel = 'shortcut icon';
        // need to ensure we double check that favicon rel is in fact 'shortcut icon'
        newFavicon.href = this.canvas.toDataURL('image/png');
        
        
        const existingFavicon = document.querySelector("link[rel='shortcut icon']");
        if (existingFavicon) {
            existingFavicon.remove();
        }

        document.head.appendChild(newFavicon);
    }

    public restoreOriginal() {
        if (!this.originalFavicon) return;

        const currentFavicon = document.querySelector("link[rel='shortcut icon']");
        if (currentFavicon) currentFavicon.remove();

        document.head.appendChild(this.originalFavicon.cloneNode(true));
    }
}