import React from "react";
import { Box } from "@twilio-paste/core/box";
import { Text } from "@twilio-paste/core/text";
import { Card } from "@twilio-paste/core/card";
import { MediaObject, MediaFigure, MediaBody } from "@twilio-paste/core/media-object";
import { LinkExternalIcon } from "@twilio-paste/icons/esm/LinkExternalIcon";

interface LinkPreviewProps {
    metadata: {
        title: string | null;
        description: string | null;
        img: string | null;
        domain: string | null;
        requestUrl: string;
    };
}

export const LinkPreview: React.FC<LinkPreviewProps> = ({ metadata }) => {

    return (
        <Box marginTop="space20" width="100%">
            <Card padding="space30">
                    <Text
                        as="a"
                        href={metadata.requestUrl} 
                        target="_blank" 
                        rel="noopener noreferrer"
                        style={{ textDecoration: 'none', color: 'inherit' }}
                    >
                        <MediaObject as="div">
                            {metadata.img && (
                                <MediaFigure spacing="space30">
                                    <Box
                                        as="img"
                                        src={metadata.img}
                                        alt={metadata.title || "Link preview"}
                                        width="120px"
                                        height="80px"
                                        style={{ objectFit: "contain" }}
                                    />
                                </MediaFigure>
                            )}
                            <MediaBody>
                                <Text as="p" fontSize="fontSize30" fontWeight="fontWeightBold">
                                    {metadata.title || "No title available"}
                                </Text>
                                {metadata.description && (
                                    <Text 
                                        as="p" 
                                        fontSize="fontSize20" 
                                        color="colorTextWeak"
                                        style={{
                                            display: "-webkit-box",
                                            WebkitLineClamp: 2,
                                            WebkitBoxOrient: "vertical",
                                            overflow: "hidden"
                                        }}
                                    >
                                        {metadata.description}
                                    </Text>
                                )}
                                <Box display="flex" alignItems="center" marginTop="space20">
                                    <LinkExternalIcon 
                                        decorative={true} 
                                        size="sizeIcon10" 
                                        color="colorTextWeak" 
                                    />
                                    <Text 
                                        as="span" 
                                        fontSize="fontSize20" 
                                        marginLeft="space20"
                                        color="colorTextWeak"
                                    >
                                        {metadata.domain || new URL(metadata.requestUrl).hostname}
                                    </Text>
                                </Box>
                            </MediaBody>
                        </MediaObject>
                    </Text>
            </Card>
        </Box>
    );
};