import { BoxStyleProps } from "@twilio-paste/core/box";
import { TextStyleProps } from "@twilio-paste/core/text";
import { isMobile } from "react-device-detect";

export const webchatContainerStyles: BoxStyleProps = {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "end",
    paddingY: "space40",
    paddingX: "space20",
    borderBottomLeftRadius: isMobile ? "borderRadius0" : "borderRadius20",
    borderBottomRightRadius: isMobile ? "borderRadius0" : "borderRadius20",
    borderTopColor: "colorBorderWeaker",
    borderTopWidth: "borderWidth10",
    borderTopStyle: "solid"
};

export const webchatFooterStyles: TextStyleProps = {
    color: "colorText",
    marginLeft: "space30",
    marginTop: "space20",
    fontSize: "fontSize30",
    fontWeight: "fontWeightSemibold"
};

export const webchatSubFooterStyles: TextStyleProps = {
    color: "colorText",
    marginLeft: "space30",
    marginTop: "space20",
    fontSize: "fontSize20",
    fontWeight: "fontWeightNormal"
};
