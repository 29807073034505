import { useRef, useCallback, useEffect } from "react";
import { useSelector } from "react-redux";
import { createSelector } from "reselect";

import type { AppState } from "../store/definitions";

interface UseBotTimeoutOptions {
    waitInSeconds?: number;
    onTimeout?: () => void;
    onCancel?: () => void;
}


const useBotTimeoutSelector = createSelector(
    (state: AppState) => state,
    (state) => ({
        chat: state.chat,
        conversation: state.chat.conversation,
    })
);

export const useBotTimeout = ({
    waitInSeconds,
}: UseBotTimeoutOptions) => {

    const { chat, conversation } = useSelector(useBotTimeoutSelector);
    const timeoutRef = useRef<NodeJS.Timeout | null>(null);

    useEffect(() => {
        const checkParticipants = () => {
        const hasMultipleParticipants = (conversation?._participants?.size ?? 0) > 1;

            if (hasMultipleParticipants) {
                cancelTimeout();
            }
        }
        checkParticipants();
    }, [chat])

    const startTimeout = useCallback(async () => {
        if (!waitInSeconds) {
            console.error("No timeout length specified.");
            return;
        }

        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
        }

        await new Promise<void>((resolve) => {
            timeoutRef.current = setTimeout(() => {
                resolve();
            }, waitInSeconds * 1000);
        });
    }, [waitInSeconds])

    const cancelTimeout = useCallback(() => {
        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
            timeoutRef.current = null;
        }
    }, [timeoutRef]);

    return {
        start: startTimeout,
        cancel: cancelTimeout
    }
}

