import { Box } from "@twilio-paste/core/box"
import { Label } from "@twilio-paste/core/label";
import { Text } from "@twilio-paste/core";
import { Input } from "@twilio-paste/core/input";
import { fieldStyles } from "../styles/PreEngagementFormPhase.styles";
import { useDispatch, useSelector } from "react-redux";
import { useValidation } from "../../providers/ValidationContext";
import { createSelector } from "reselect";
import type { AppState } from "../../store/definitions";
import { updatePreEngagementData } from "../../store/actions/genericActions";
import { ThunkDispatch } from "redux-thunk";
import { ChatReducer } from "../../store/chat.reducer";
import { SessionReducer } from "../../store/session.reducer";
import { AnyAction } from "redux";
import type { WebchatConfigForm } from "@ciptex/conversation-sdk";
// @ts-expect-error forcing type to be included
type Field = WebchatConfigForm['fields'][number];

type InputField = Extract<Field, { type: 'text' | 'email' | 'hidden' | 'number' | 'password' | 'search' | 'tel' | 'date' | 'time' | 'url' }>;

interface DefaultFieldProps {
    field: InputField;
    preEngagementData?: Record<string, string>;
}

const defaultFieldSelector = createSelector(
    (state: AppState) => state,
    (state) => ( { preEngagementData: state.session.preEngagementData })
);
export const DefaultField = ({ field }: DefaultFieldProps) => {

    const { preEngagementData } = useSelector(defaultFieldSelector) || {};
    const dispatch = useDispatch<ThunkDispatch<ChatReducer | SessionReducer, unknown, AnyAction>>();
    const { validateField, errors } = useValidation();

    return (
        <Box {...fieldStyles}>
        <Label htmlFor={field.id}>{field.label}</Label>
        <Input
            element="DEFAULT_INPUT"
            type={field.type}
            placeholder={field.placeholder}
            name={field.id}
            id={field.id}
            maxLength={field.maxLength}
            data-test={`pre-engagement-chat-form-${field.id}-input`}
            value={preEngagementData?.[field.id] as string ?? ""}
            onChange={(e) => dispatch(updatePreEngagementData({ [field.id]: e.target.value }))}
            onBlur={(e) => validateField(field.id, e.target.value)}
            autoComplete={field.id}
            required={field.required}
        />
        {errors[field.id] && (
            <Text color="colorTextError" as={"p"}>
                {errors[field.id]}
            </Text>
        )}
    </Box>
    );
}