import { FC, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { createSelector } from "reselect";
import { DEFAULT_BOT_MESSAGE_DELAY_SECONDS } from "../../constants";
import { useCanHandleBotStep } from "../../hooks/useCanHandleBotSteps";
import { useBotTimeout } from "../../hooks/useBotTimeout";
import {
    changeEngagementPhase,
    setActiveBotStep,
    setActiveBotStepStatus,
    updateTaskAttributes
} from "../../store/actions/genericActions";
import { AppState, BotStepStatus, EngagementPhase } from "../../store/definitions";
import { BotStepType, UpdateTaskAttributesBotStep } from "../../types/bots";

interface UpdateTaskAttributesStepProps {
    botStep: UpdateTaskAttributesBotStep;
}

const updateTaskAttributesStepSelector = createSelector(
    (state: AppState) => state,
    (state) => ({
        conversation: state.chat.conversation,
        isEscalated: state.bot.isEscalated
    })
);

export const UpdateTaskAttributesStep: FC<UpdateTaskAttributesStepProps> = ({ botStep }) => {
    const dispatch = useDispatch();
    const { canHandleBotStep } = useCanHandleBotStep(botStep, {
        requiredStepType: BotStepType.UpdateTaskAttributes
    });
    const { conversation, isEscalated } = useSelector(updateTaskAttributesStepSelector);

    const handleTimeout = useBotTimeout({
        waitInSeconds : botStep.waitInSeconds ?? DEFAULT_BOT_MESSAGE_DELAY_SECONDS
    });

    useEffect(() => {
        const handleBotStep = async () => {
            if (!canHandleBotStep) {
                return;
            }

            // Mark step as being processed, and hide message input
            dispatch(setActiveBotStepStatus(BotStepStatus.Processing));

            // adds some delay to the bot step
            handleTimeout.start();

            // Update the task attributes
            if (botStep.taskAttributes) {
                dispatch(updateTaskAttributes(botStep.taskAttributes, conversation?.sid));
            }

            if (botStep.nextStepId) {
                dispatch(setActiveBotStepStatus(BotStepStatus.Unhandled));
                dispatch(setActiveBotStep(botStep.nextStepId));
            } else if (isEscalated) {
                dispatch(changeEngagementPhase({ phase: EngagementPhase.MessagingCanvas }));
            }
        };
        handleBotStep();
    }, [dispatch, canHandleBotStep, botStep, conversation]);

    return <></>;
};
