import { Client } from "@twilio/conversations";
import log from "loglevel";
import { AnyAction, Dispatch } from "redux";

import { ThunkDispatch } from "redux-thunk";
import { MESSAGES_LOAD_COUNT } from "../../constants";
import { notifications } from "../../notifications";
import { sessionDataHandler } from "../../sessionDataHandler";
import { ChatReducer } from "../chat.reducer";
import { BotState, ConfigState, EngagementPhase } from "../definitions";
import { SessionReducer } from "../session.reducer";
import { ACTION_LOAD_CONFIG, ACTION_START_BOT_SESSION, ACTION_START_SESSION } from "./actionTypes";
import { addNotification, changeEngagementPhase, resetBotData, sendConversationMessage } from "./genericActions";
import { initClientListeners } from "./listeners/clientListener";
import { initConversationListener } from "./listeners/conversationListener";
import { initEventFlowsListener } from "./listeners/eventFlowListener";
import { initMessagesListener } from "./listeners/messagesListener";
import { initParticipantsListener } from "./listeners/participantsListener";

export function initConfig(config: ConfigState) {
    return {
        type: ACTION_LOAD_CONFIG,
        payload: config
    };
}

export function initSession({
    token,
    conversationSid,
    botState,
    conversationsClient,
    useBots,
    studioFlowSid
}: {
    token: string;
    conversationSid: string;
    botState?: BotState;
    conversationsClient?: Client;
    useBots?: boolean;
    studioFlowSid?: string;
}) {
    return async (dispatch: ThunkDispatch<ChatReducer | SessionReducer, unknown, AnyAction>) => {
        let conversation;
        let participants;
        let users;
        let messages;

        try {
            if (!conversationsClient) {
                conversationsClient = new Client(token);
            }
            try {
                conversation = await conversationsClient.peekConversationBySid(conversationSid);
            } catch (e) {
                console.log(e);
                dispatch(addNotification(notifications.failedToInitSessionNotification("Couldn't load conversation")));
                dispatch(changeEngagementPhase({ phase: EngagementPhase.PreEngagementForm }));
                return;
            }

            participants = await conversation.getParticipants();
            users = await Promise.all(participants.map(async (p) => p.getUser()));
            messages = (await conversation.getMessages(MESSAGES_LOAD_COUNT)).items;
        } catch (e) {
            log.error("Something went wrong when initializing session", e);
            throw e;
        }

        dispatch({
            type: ACTION_START_SESSION,
            payload: {
                token,
                conversationSid,
                conversationsClient,
                conversation,
                users,
                participants,
                messages,
                conversationState: conversation.state?.current,
                currentPhase:
                    useBots
                        ? EngagementPhase.BotCanvas
                        : EngagementPhase.MessagingCanvas,
                botState
            }
        });

        initClientListeners(conversationsClient, dispatch);
        initConversationListener(conversation, dispatch);
        initMessagesListener(conversation, dispatch);
        initParticipantsListener(conversation, dispatch);
        if (useBots) {
            initEventFlowsListener(token, conversation, dispatch);
        }

        if (studioFlowSid && studioFlowSid !== "") {
            //kick off the studio flow by sending a message
            dispatch(
                sendConversationMessage({
                    sentByBot: true,
                    conversation,
                    body: "Connecting...",
                    storeResponse: false,
                    isKickOffMessage: true
                })
            );
        }
    };
}

export function initBotSession({ token, conversationSid }: { token: string; conversationSid: string }) {
    return async (dispatch: Dispatch) => {
        // gets the bot config
        const botWrapper = await sessionDataHandler.getBotConfig({ url: window.location.href, conversationSid });
        dispatch(resetBotData());
        dispatch({
            type: ACTION_START_BOT_SESSION,
            payload: {
                bot: botWrapper.bot,
                widgetConfig: botWrapper.widgetConfig,
                activeBotStepId: botWrapper.bot.botConfig.initialStepId ?? 1
            }
        });

        // initialises a regular session
        await initSession({ token, conversationSid, useBots: true })(dispatch);
    };
}
