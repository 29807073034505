import { ResizableBox } from "react-resizable";
import { Box } from "@twilio-paste/core/box"
import type { ResizeCallbackData } from "react-resizable";
import { isMobile } from "react-device-detect";
import { resizeHandleStyles } from "./styles/RootContainer.styles";

type ResizableContainerProps = {
    enableDragFlag: boolean | undefined;
    enableResizeFlag: boolean | undefined;
    size: { width: number; height: number };
    onChatResize: (event: React.SyntheticEvent, { size }: ResizeCallbackData) => void;
    cssOverrides?: {
        container?: {
            width?: string;
            height?: string;
        }}
    getContainerHeight: (overrideHeight?: string) => string;
    getContainerWidth: (overrideWidth?: string) => string;
    configSizeFormatter: (value: string) => number;
    children: React.ReactNode;
};

export const ResizableContainer: React.FC<ResizableContainerProps> = ({
    enableDragFlag,
    enableResizeFlag,
    size,
    onChatResize,
    cssOverrides,
    getContainerHeight,
    getContainerWidth,
    configSizeFormatter,
    children,
}: ResizableContainerProps) => {

    if (enableResizeFlag && !isMobile) {
        return (
            <Box marginBottom={(enableResizeFlag && !enableDragFlag) ? "space40" : "space0"}>
            <ResizableBox
                width={size.width}
                height={size.height}
                onResize={onChatResize}
                minConstraints={[
                    configSizeFormatter(getContainerWidth(cssOverrides?.container?.width)),
                    configSizeFormatter(getContainerHeight(cssOverrides?.container?.height)),
                ]}
                maxConstraints={[800, 800]}
                resizeHandles={["n"]}
                handle={<div style={resizeHandleStyles} className="resize-handle" />}
                axis="y"
            >
                {children}
            </ResizableBox>
            </Box>
        );
    }

    return (
        <Box height={isMobile ? "100%" : size.height} width={isMobile ? "100%" : size.width} marginBottom="space40">
            {children}
        </Box>
    );
}
