import { Box } from "@twilio-paste/core/box";
import { Button } from "@twilio-paste/core/button";
import { Text } from "@twilio-paste/core/text";
import { useDispatch, useSelector } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import { createSelector } from "reselect"

import { changeEngagementPhase } from "../../store/actions/genericActions";
import { ChatReducer } from "../../store/chat.reducer";
import { AppState, EngagementPhase } from "../../store/definitions";
import { SessionReducer } from "../../store/session.reducer";
import { Header } from "../Header";
import { Footer } from "../Footer";
import { NotificationBar } from "../NotificationBar";
import { initialiseChat } from "../../utils/sessionUtils"

const channelSelectionPhaseSelector = createSelector(
    (state: AppState) => state,
    (state) => ({
        enablePreEngagementForm: state.config.flags?.preEngagementFormEnabled,
        enableChannelSelection: state.config.flags?.channelSelectionEnabled,
        twilioTaskQueueSid: state.config.twilio.taskQueueSid,
        twilioWorkflowSid: state.config.twilio.workflowSid,
        isBotConversation: state.config.bot.useBots ?? false,
        studioFlowSid: state.config.twilio.studioFlowSid,
        channelSelectionPhaseHeader: state.config.theme.content?.channelSelection?.header,
        channelSelectionPhaseSubHeader: state.config.theme.content?.channelSelection?.subHeader
    })
);

export const ChannelSelectionPhase = () => {
    const dispatch = useDispatch<ThunkDispatch<ChatReducer | SessionReducer, unknown, AnyAction>>();
    const { 
        enablePreEngagementForm, 
        enableChannelSelection,
        twilioTaskQueueSid, 
        twilioWorkflowSid,
        isBotConversation,
        studioFlowSid,
        channelSelectionPhaseHeader,
        channelSelectionPhaseSubHeader
    } = useSelector(channelSelectionPhaseSelector);


    const defaultChannelSelectionHeader = "How would you like to connect? ";
    const defaultChannelSelectionSubHeader = "Choose your preferred way to communicate with us."

    const handleChatSelection = () => {
        if (enableChannelSelection && !enablePreEngagementForm) {
            initialiseChat(dispatch, isBotConversation, twilioTaskQueueSid, twilioWorkflowSid, studioFlowSid);
        } else {
            dispatch(changeEngagementPhase({ phase: EngagementPhase.PreEngagementForm }));
        }
    };

    return (
        <>
            <Header />
            <NotificationBar />
            <Box
                as="div"
                padding="space60"
                display="flex"
                flexDirection="column"
                height="100%"
            >
                <Box marginBottom="space60">
                    <Box display="flex" flexDirection="row" >
                        <Text 
                            as="h3" 
                            fontSize="fontSize60" 
                            fontWeight="fontWeightBold"
                            marginBottom="space40"
                        >
                            { channelSelectionPhaseHeader ?? defaultChannelSelectionHeader }
                        </Text>
                    </Box>
                    <Text 
                        as="p" 
                        fontSize="fontSize30" 
                        color="colorTextWeak"
                    >
                        { channelSelectionPhaseSubHeader ?? defaultChannelSelectionSubHeader }
                    </Text>
                </Box>
                <Box
                    display="flex"
                    flexDirection="column"
                >
                    <Button
                        variant="secondary"
                        onClick={handleChatSelection}
                        data-test="channel-selection-chat-button"
                    >
                        Chat
                    </Button>
                    {/* Additional channel buttons can be added here in the future */}
                </Box>
            </Box>
            <Footer />
        </>
    );
};