/* eslint-disable @typescript-eslint/no-explicit-any */
import { Box } from "@twilio-paste/core/box"
import { Checkbox, CheckboxGroup } from "@twilio-paste/core/checkbox"
import { fieldStyles } from "../styles/PreEngagementFormPhase.styles";
import { useDispatch, useSelector } from "react-redux";
import { createSelector } from "reselect";
import { updatePreEngagementData } from "../../store/actions/genericActions";
import { ThunkDispatch } from "redux-thunk";
import { ChatReducer } from "../../store/chat.reducer";
import { SessionReducer } from "../../store/session.reducer";
import { AnyAction } from "redux";
import { useCallback, useState } from "react";
import { useValidation } from "../../providers/ValidationContext";

import type { AppState } from "../../store/definitions";
import type { WebchatConfigForm } from "@ciptex/conversation-sdk";

type Field = NonNullable<WebchatConfigForm["fields"]>[number];

interface CheckboxFieldProps {
    field: Field & { type: "checkbox" };
}


const checkboxFieldSelector = createSelector(
    (state: AppState) => state,
    (state) => ( {preEngagementData: state.session.preEngagementData })
);
export const CheckboxField = ({ field }: CheckboxFieldProps) => {

    const { preEngagementData } = useSelector(checkboxFieldSelector) || {};
    const dispatch = useDispatch<ThunkDispatch<ChatReducer | SessionReducer, unknown, AnyAction>>();
    const {validateField, errors} = useValidation();
    
    const initialState = field.options?.map(option => 
        preEngagementData?.[field.id]?.split(',').includes(option.value) ?? false
    ) ?? [];

    const [checkBoxState, setCheckBoxState] = useState(initialState);

    const handleCheckboxChange = useCallback((index: number, checked: boolean) => {
        const newState = [...checkBoxState];
        newState[index] = checked;
        setCheckBoxState(newState);

        const selectedValues = field.options
            ?.filter((_, i) => newState[i])
            .map(option => option.value)
            .join(',');

        dispatch(updatePreEngagementData({ [field.id]: selectedValues || '' }));
        validateField(field.id, selectedValues || '');
    }, [dispatch, checkBoxState, field.id, field.options]);


    return (
        <Box {...fieldStyles}>
            <CheckboxGroup
                legend={field.label}
                element="DEFAULT_CHECKBOX_GROUP"
                name={field.id}
                id={field.id}
                data-test={`pre-engagement-chat-form-${field.id}-input`}
                errorText={errors[field.id]}
                required={field.required}
            >
                {field.options?.map((option, index) => {
                    return (
                    <Checkbox
                        key={`${field.id}-${option.value}`}
                        id={`${field.id}-${option.value}`}
                        value={option.value}
                        checked={checkBoxState[index]}
                        data-test={`pre-engagement-chat-form-${field.id}-input-${option.value}`}
                        onChange={e => handleCheckboxChange(index, e.target.checked)}
                        hasError={!!errors[field.id]}
                    >
                        {option.label}
                    </Checkbox>);
                }) ?? 
                <Checkbox value="">
                    No options available
                </Checkbox>
                }
            </CheckboxGroup>
        </Box>
    );
}