import { CustomizationProvider, CustomizationProviderProps } from "@twilio-paste/core/customization";
import { CSSProperties, FC, PropsWithChildren, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";

import { createSelector } from "reselect";
import { DEFAULT_CONFIG_NAME } from "../constants";
import { sessionDataHandler } from "../sessionDataHandler";
import { changeEngagementPhase } from "../store/actions/genericActions";
import { initConfig, initSession } from "../store/actions/initActions";
import type { ChatReducer } from "../store/chat.reducer";
import { AppState, EngagementPhase } from "../store/definitions";
import type { SessionReducer } from "../store/session.reducer";
import { RootContainer } from "./RootContainer";
import { WidgetSkeletonLoader } from "./WidgetSkeletonLoader";
import { Box } from "@twilio-paste/core/box";

const AnyCustomizationProvider: FC<PropsWithChildren<CustomizationProviderProps> & { style?: CSSProperties }> =
    CustomizationProvider;

const webchatWidgetSelector = createSelector(
    (state: AppState) => state,
    (state) => ({
        configName: state.config.name,
        theme: state.config.theme,
        useBots: state.config.bot.useBots,
        studioFlowSid: state.config.twilio.studioFlowSid,
        enableChannelSelection: state.config.flags?.channelSelectionEnabled,
        isConfigLoaded: state.config.name !== DEFAULT_CONFIG_NAME
    })
);

export function WebchatWidget({ webchatConfigSid, accountSid }: { webchatConfigSid: string; accountSid: string }) {
    const { theme, useBots, studioFlowSid, configName, enableChannelSelection, isConfigLoaded } = useSelector(webchatWidgetSelector);
    const dispatch = useDispatch<ThunkDispatch<ChatReducer | SessionReducer, unknown, AnyAction>>();
    const [configLoading, setConfigLoading] = useState(false);

    useEffect(() => {
        const setupConfig = async () => {
            setConfigLoading(true);
            try {
                const config = await sessionDataHandler.getConfig({ webchatConfigSid, accountSid });
                sessionDataHandler.setEndpoint(config.serverUrl);
                dispatch(initConfig(config));
            } catch (error) {
                console.error("Failed to load config");
            }
            setConfigLoading(false);
        };

        if (configName === DEFAULT_CONFIG_NAME && !configLoading) {
            setupConfig();
        }
    }, [dispatch, webchatConfigSid, accountSid, configName, configLoading]);

    useEffect(() => {
        if (!isConfigLoaded || configLoading) {
            return;
        }

        const initializeSession = async () => {
            const data = sessionDataHandler.tryResumeExistingSession();
            if (data) {
                try {
                    await dispatch(
                        initSession({
                            token: data.token,
                            conversationSid: data.conversationSid,
                            botState: data.botState,
                            useBots,
                            studioFlowSid
                        })
                    );
                } catch (e) {
                    dispatch(changeEngagementPhase({ 
                        phase: enableChannelSelection ? EngagementPhase.ChannelSelection : EngagementPhase.PreEngagementForm 
                    }));
                }
            } else {
                dispatch(changeEngagementPhase({ 
                    phase: enableChannelSelection ? EngagementPhase.ChannelSelection : EngagementPhase.PreEngagementForm 
                }));
            }
        };

        initializeSession();
    }, [dispatch, isConfigLoaded, configLoading, enableChannelSelection, useBots, studioFlowSid]);

    useEffect(() => {
        if (theme.overrides?.fonts?.customFontImport) {
            const style = document.createElement('style');
            style.innerHTML = theme.overrides?.fonts?.customFontImport;
            document.head.appendChild(style);

            return () => {
                document.head.removeChild(style);
            }
        }
    }, [theme?.overrides?.fonts?.customFontImport]);

    return (
        <Box style={{zIndex: 1000}}>
        <AnyCustomizationProvider
            baseTheme={theme?.isLight ? "default" : "dark"}
            theme={theme?.overrides}
            elements={{
                DEFAULT_INPUT_ELEMENT: {
                    fontSize: "fontSize40"
                },
                DEFAULT_TEXT_AREA_ELEMENT: {
                    fontSize: "fontSize40"
                },
                TEXTAREA_ELEMENT: {
                    boxShadow: "none!important" as "none",
                    fontSize: "fontSize40"
                },
                MESSAGE_INPUT: {
                    boxShadow: "none!important" as "none",
                    fontSize: "fontSize40"
                },
                MESSAGE_INPUT_BOX: {
                    display: "inline-block",
                    boxShadow: "none!important" as "none",
                    fontSize: "fontSize40!important" as "fontSize40"
                },
                ALERT: {
                    paddingTop: "space30",
                    paddingBottom: "space30"
                },
                BUTTON: {
                    "&[aria-disabled='true'][color='colorTextLink']": {
                        color: "colorTextLinkWeak"
                    }
                }
            }}
            style={{ minHeight: "100%", minWidth: "100%" }}
        >
            {configLoading ? <WidgetSkeletonLoader /> : <RootContainer />}
        </AnyCustomizationProvider>
        </Box>
    );
}
