import { BoxStyleProps } from "@twilio-paste/core/box";
import { TextStyleProps } from "@twilio-paste/core/text";
import { isMobile } from "react-device-detect";

export const webchatContainerStyles: BoxStyleProps = {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: "colorBackgroundPrimaryStronger",
    padding: "space20",
    paddingTop: "space40",
    paddingBottom: "space40",
    borderTopLeftRadius: isMobile ? "borderRadius0" : "borderRadius20",
    borderTopRightRadius: isMobile ? "borderRadius0" : "borderRadius20",
    cursor: "move"
};

export const webchatTitleStyles: TextStyleProps = {
    color: "colorTextWeakest",
    marginLeft: "space30"
};

export const webchatSubHeaderStyles: TextStyleProps = {
    color: "colorTextWeakest",
    marginLeft: "space30",
    marginTop: "space20",
    fontSize: "fontSize20",
    fontWeight: "fontWeightNormal"
};

export const webchatLogoStyles: BoxStyleProps = {
    display: "block",
    height: "40px",
    borderRadius: "borderRadius20",
    marginLeft: "space30",
    marginRight: "space10",
    userSelect: "none",
};

export const webchatCloseButtonStyles: BoxStyleProps = {
    border: "none",
    backgroundColor: "colorBackgroundPrimary",
    display: "flex",
    height: "sizeIcon70",
    width: "sizeIcon70",
    fontSize: "fontSize50",
    alignItems: "center",
    justifyContent: "center",
    color: "colorTextWeakest",
    cursor: "pointer",
    transition: "background-color 0.2s",
    outline: "0px",
    padding: "space0",
    borderRadius: "borderRadius60",

    _hover: {
        backgroundColor: "colorBackgroundPrimaryStronger"
    },
    _focusVisible: {
        backgroundColor: "colorBackgroundPrimaryStronger",
        boxShadow: "shadowFocus"
    }
};
