import { FC, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { createSelector } from "reselect";
import { DEFAULT_BOT_MESSAGE_DELAY_SECONDS } from "../../constants";
import { useCanHandleBotStep } from "../../hooks/useCanHandleBotSteps";
import { useBotTimeout } from "../../hooks/useBotTimeout";
import {
    changeEngagementPhase,
    setActiveBotStep,
    setActiveBotStepStatus,
    updateTaskAttributes
} from "../../store/actions/genericActions";
import { AppState, BotStepStatus, EngagementPhase } from "../../store/definitions";
import { AddToIVRPathBotStep, BotStepType } from "../../types/bots";
import { addToIvrPath } from "../../utils/addToIvrPath";

interface AddToIVRPathStepProps {
    botStep: AddToIVRPathBotStep;
}

const addToIvrPathStepSelector = createSelector(
    (state: AppState) => state,
    (state) => ({ taskAttributes: state.bot?.taskAttributes, isEscalated: state.bot?.isEscalated })
);

export const AddToIVRPathStep: FC<AddToIVRPathStepProps> = ({ botStep }) => {
    const dispatch = useDispatch();
    const { canHandleBotStep } = useCanHandleBotStep(botStep, {
        requiredStepType: BotStepType.AddToIVRPath
    });
    const { taskAttributes, isEscalated } = useSelector(addToIvrPathStepSelector);

    const handleTimeout = useBotTimeout({
        waitInSeconds : botStep.waitInSeconds ?? DEFAULT_BOT_MESSAGE_DELAY_SECONDS
    });

    useEffect(() => {
        const handleBotStep = async () => {
            if (!canHandleBotStep) {
                return;
            }

            // Mark step as being processed, and hide message input
            dispatch(setActiveBotStepStatus(BotStepStatus.Processing));

            // adds some delay to the bot step
            handleTimeout.start();

            // Update the task attributes
            if (botStep.ivrPath) {
                const mergedIvrPath = addToIvrPath((taskAttributes?.ivrPath ?? "") as string, botStep.ivrPath);
                dispatch(
                    updateTaskAttributes({
                        ivrPath: mergedIvrPath
                    })
                );
            }

            if (botStep.nextStepId) {
                dispatch(setActiveBotStepStatus(BotStepStatus.Unhandled));
                dispatch(setActiveBotStep(botStep.nextStepId));
            } else if (isEscalated) {
                dispatch(changeEngagementPhase({ phase: EngagementPhase.MessagingCanvas }));
            }
        };
            handleBotStep();
    }, [dispatch, canHandleBotStep, botStep, isEscalated]);

    return <></>;
};
