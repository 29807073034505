import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import { changeEngagementPhase, addNotification } from "../store/actions/genericActions";
import { initBotSession, initSession } from "../store/actions/initActions";
import { ChatReducer } from "../store/chat.reducer";
import { SessionReducer } from "../store/session.reducer";
import { sessionDataHandler } from "../sessionDataHandler";
import { notifications } from "../notifications";
import { EngagementPhase } from "../store/definitions";

export const initialiseChat = async (
    dispatch: ThunkDispatch<ChatReducer | SessionReducer, unknown, AnyAction>,
    isBotConversation: boolean,
    twilioTaskQueueSid?: string,
    twilioWorkflowSid?: string,
    studioFlowSid?: string,
    phase: EngagementPhase = EngagementPhase.Loading
) => {
    dispatch(changeEngagementPhase({ phase }));

    try {
        const currentURL = window.location.href;
        const { token, conversationSid } = await sessionDataHandler.fetchAndStoreNewSession({
            twilioTaskQueueSid,
            twilioWorkflowSid,
            isBotConversation,
            studioFlowSid,
            formData: { originURL: currentURL }
        });

        if (isBotConversation) {
            dispatch(initBotSession({ token, conversationSid }));
        } else {
            dispatch(initSession({ token, conversationSid, studioFlowSid }));
        }
    } catch (err) {
        dispatch(addNotification(notifications.failedToInitSessionNotification((err as Error).message)));
        dispatch(changeEngagementPhase({ phase: EngagementPhase.ChannelSelection }));
    }
};
