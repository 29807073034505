import { sanitize } from "dompurify";

const URL_REGEX = /(https?:\/\/[^\s]+)|(www\.[^\s]+)/gi;

export const extractUrls = (text: string): string[] => {
    const matches = text.match(URL_REGEX);
    
    if (!matches) return [];

    return matches.map(url => 
        url.startsWith('www.') ? `https://${url}` : url
    );
};

export const parseMessageBody = (body: string): string => {
    const textWithLinks = body.replace(URL_REGEX, (url) => {
        const fullUrl = url.startsWith('www.') ? `https://${url}` : url;
        return `<a href="${fullUrl}" target="_blank" rel="noopener noreferrer" data-url="true">${url}</a>`;
    });
    
    return sanitize(textWithLinks);
};