import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";

import { WebchatWidget } from "./components/WebchatWidget";
import { initLogger } from "./logger";
import { updatePreEngagementData } from "./store/actions/genericActions";
import { PreEngagementData } from "./store/definitions";
import { store } from "./store/store";

const ROOT_STYLES = `
    position: fixed !important;
    z-index: 2147483647 !important;
    bottom: 0 !important;
    right: 0 !important;
    width: 0 !important;
    height: 0 !important;
    overflow: visible !important;
    isolation: isolate !important;
`.replace(/\s+/g, ' ').trim();

const initWebchat = async ({webchatConfigSid, accountSid, retry}: {webchatConfigSid: string, accountSid: string, retry?: number}) => {
    initLogger();
    const rootElement = document.getElementById("twilio-webchat-widget-root");

    if (rootElement) {
        const root = createRoot(rootElement);
        root.render(
            <Provider store={store}>
                <WebchatWidget webchatConfigSid={webchatConfigSid} accountSid={accountSid} />
            </Provider>
        );
    } else if(!retry || retry < 2) {
        setTimeout(() => {
            return initWebchat({webchatConfigSid, accountSid, retry: retry ? retry + 1 : 1});
        }, 200 * (retry ?? 1));
    }

    if (window.Cypress) {
        window.store = store;
    }
};

// Create the global function for adding additional form data
const setFormData = (data: Partial<PreEngagementData>) => {
    store.dispatch(updatePreEngagementData(data));
};

declare global {
    interface Window {
        Twilio: {
            initWebchat: (options: { webchatConfigSid: string, accountSid: string, retry?: number }) => void;
            setFormData: (data: Partial<PreEngagementData>) => void;
        };
        Cypress: Cypress.Cypress;
        store: typeof store;
    }
}

// Expose `initWebchat` and `addAdditionalFormData` functions to window object
Object.assign(window, {
    Twilio: {
        initWebchat,
        setFormData
    }
});

// Add this new code to initialize the webchat when the DOM is loaded
window.addEventListener("DOMContentLoaded", () => {
    // Create style element for our CSS rules
    const styleElement = document.createElement('style');
    styleElement.textContent = `
        #twilio-webchat-widget-root {
            ${ROOT_STYLES}
        }
        #twilio-webchat-widget-root * {
            pointer-events: auto !important;
        }
    `;
    document.head.appendChild(styleElement);

    // Add this to create the root div if it doesn't exist
    if (!document.getElementById("twilio-webchat-widget-root")) {
        const rootDiv = document.createElement("div");
        rootDiv.id = "twilio-webchat-widget-root";
        document.body.appendChild(rootDiv);
    }
});
