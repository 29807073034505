import { Box } from "@twilio-paste/core/box";
import { Text } from "@twilio-paste/core/text";
import { useSelector } from "react-redux";
import { createSelector } from "reselect";
import { AppState } from "../store/definitions";
import { webchatContainerStyles, webchatFooterStyles, webchatSubFooterStyles } from "./styles/Footer.styles";

export const footerSelector = createSelector(
    (state: AppState) => state,
    (state) => ({
        footer: state.config.theme.content?.footer,
        subFooter: state.config.theme.content?.subFooter
    })
);

export const Footer = () => {
    const { footer, subFooter } = useSelector(footerSelector);

    return (
        (footer || subFooter) && (
            <Box {...webchatContainerStyles}>
                <Box display="flex" justifyContent="left" alignContent="center">
                    <Box display="flex" flexDirection="column" alignItems="flex-start">
                        {footer && (
                            <Text as="h2" {...webchatFooterStyles}>
                                {footer}
                            </Text>
                        )}
                        {subFooter && (
                            <Text as="p" {...webchatSubFooterStyles} dangerouslySetInnerHTML={{ __html: subFooter }} />
                        )}
                    </Box>
                </Box>
            </Box>
        )
    );
};
