import { BoxStyleProps } from "@twilio-paste/core/box";

export const formStyles: BoxStyleProps = {
    borderTopColor: "colorBorderWeaker",
    borderTopWidth: "borderWidth10",
    borderTopStyle: "solid",
    padding: "space20"
};

export const innerInputStyles: BoxStyleProps = {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    justifyContent: "space-between",
    columnGap: "space30",
    rowGap: "space30",
    paddingY: "space20"
};

export const textAreaContainerStyles: BoxStyleProps = {
    marginTop: "auto",
    marginBottom: "auto",
    flex: 1
};

export const messageOptionContainerStyles: BoxStyleProps = {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "left",

    _notLast: {
        marginRight: "space0"
    }
};

export const filePreviewContainerStyles: BoxStyleProps = {
    display: "flex",
    flexDirection: "column",
    overflow: "auto",
    maxHeight: "300px",
    position: "relative",
    paddingLeft: "space30",
    paddingRight: "space30"
};

export const textAreaInputrStyles: BoxStyleProps = {
    fontSize: "fontSize40"
};
