import { Box } from "@twilio-paste/core/box";
import { Button } from "@twilio-paste/core/button";
import { FC, useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { createSelector } from "reselect";
import { DEFAULT_BOT_MESSAGE_DELAY_SECONDS } from "../../constants";
import { useCanHandleBotStep } from "../../hooks/useCanHandleBotSteps";
import { useIsTaskAccepted } from "../../hooks/useIsTaskAccepted";
import { useBotTimeout } from "../../hooks/useBotTimeout";
import {
    changeEngagementPhase,
    sendConversationMessage,
    setActiveBotStep,
    setActiveBotStepStatus,
    setShowMessageInput,
    setStoreResponse
} from "../../store/actions/genericActions";
import { ChatReducer } from "../../store/chat.reducer";
import { AppState, BotStepStatus, EngagementPhase } from "../../store/definitions";
import { SessionReducer } from "../../store/session.reducer";
import { BotStepType, MultipleChoiceBotStep } from "../../types/bots";

interface MultipleChoiceStepProps {
    botStep: MultipleChoiceBotStep;
}

const multipleChoiceStepSelector = createSelector(
    (state: AppState) => state,
    (state) => ({
        conversation: state.chat.conversation,
        activeBotStepStatus: state.bot.status,
        isEscalated: state.bot.isEscalated,
        storeResponse: state.bot.storeResponse ?? false,
        storeResponseName: state.bot.storeResponseName,
        storeResponseAttributeIndex: state.bot.storeResponseAttributeIndex,
        conversationAttributes: state.bot.taskAttributes?.conversations as Record<string, unknown> | undefined
    })
);

export const MultipleChoiceStep: FC<MultipleChoiceStepProps> = ({ botStep }) => {
    const dispatch = useDispatch<ThunkDispatch<ChatReducer | SessionReducer, unknown, AnyAction>>();
    const isTaskAccepted = useIsTaskAccepted();
    const { canHandleBotStep } = useCanHandleBotStep(botStep, {
        requiredStepType: BotStepType.MultipleChoice
    });
    const {
        conversation,
        activeBotStepStatus,
        isEscalated,
        storeResponse,
        storeResponseAttributeIndex,
        storeResponseName,
        conversationAttributes
    } = useSelector(multipleChoiceStepSelector);

    const handleTimeout = useBotTimeout({
        waitInSeconds : botStep.waitInSeconds ?? DEFAULT_BOT_MESSAGE_DELAY_SECONDS
    });

    const sendMessage = useCallback(
        async (message: string, sentByBot?: boolean, header?: string, footer?: string) => {
            dispatch(
                sendConversationMessage({
                    sentByBot,
                    conversation,
                    body: message,
                    header,
                    footer,
                    contentSid: botStep.contentSid,
                    variables: botStep.variables,
                    storeResponse,
                    storeResponseAttributeIndex,
                    storeResponseName,
                    conversationAttributes
                })
            );
        },
        [
            conversation,
            sendConversationMessage,
            storeResponse,
            storeResponseAttributeIndex,
            storeResponseName,
            conversationAttributes
        ]
    );

    useEffect(() => {
        const handleBotStep = async () => {
            if (!canHandleBotStep) {
                return;
            }

            // Mark step as being processed, and hide message input
            dispatch(setActiveBotStepStatus(BotStepStatus.Processing));
            dispatch(setShowMessageInput(false));

            // adds some delay to the bot step
            handleTimeout.start();

            // If the task was accepted in the wait, then move to the messaging canvas
            if (isTaskAccepted.current) {
                dispatch(changeEngagementPhase({ phase: EngagementPhase.MessagingCanvas }));
                return;
            }

            // Loop through the bot messages/content we need to show
            for (const contentItem of botStep.content) {
                switch (contentItem.type) {
                    case "string":
                        sendMessage(contentItem.message, true);
                        break;

                    case "seperated-string":
                        sendMessage(contentItem.body, true, contentItem.header, contentItem.footer);
                        break;

                    case "video":
                        sendMessage(contentItem.videoUrl, true);
                        break;

                    default:
                        console.error("[MultipleChoiceStep] Unknown content type", contentItem);
                }
            }

            if (botStep.storeResponseName) {
                dispatch(setStoreResponse(true, botStep.storeResponseName));
            }

            // Once all sent, show the buttons the user can interact with to response
            dispatch(setActiveBotStepStatus(BotStepStatus.WaitingForUserInput));
        };
        handleBotStep();
    }, [dispatch, sendMessage, canHandleBotStep, botStep, isTaskAccepted]);

    const shouldShowOptions = activeBotStepStatus === BotStepStatus.WaitingForUserInput && botStep.options.length > 0;

    return (
        <>
            {shouldShowOptions && (
                <Box
                    width="100%"
                    display="flex"
                    justifyContent="right"
                    columnGap="space40"
                    rowGap="space40"
                    padding="space50"
                    flexWrap="wrap"
                >
                    {botStep.options.map((option) => {
                        return (
                            <Button
                                key={option.label}
                                variant="secondary"
                                size="rounded_small"
                                onClick={() => {
                                    dispatch(setActiveBotStepStatus(BotStepStatus.Processing));
                                    sendMessage(option.label, false);
                                    switch (option.optionType) {
                                        case "simple":
                                            if (option.nextStepId) {
                                                dispatch(setActiveBotStepStatus(BotStepStatus.Unhandled));
                                                dispatch(setActiveBotStep(option.nextStepId));
                                            } else if (isEscalated) {
                                                dispatch(
                                                    changeEngagementPhase({ phase: EngagementPhase.MessagingCanvas })
                                                );
                                            }
                                            break;
                                        case "contextual":
                                            for (const variant of option.nextStepVariants) {
                                                if (!variant.previousNodeId) {
                                                    dispatch(setActiveBotStepStatus(BotStepStatus.Unhandled));
                                                    dispatch(setActiveBotStep(variant.nextStepId));
                                                    break;
                                                }
                                            }
                                            break;
                                        default:
                                            break;
                                    }
                                }}
                            >
                                {option.label}
                                {option.description && ` - ${option.description}`}
                            </Button>
                        );
                    })}
                </Box>
            )}
        </>
    );
};
