import { Box } from "@twilio-paste/core/box"
import { Label } from "@twilio-paste/core/label";
import { Select, Option } from "@twilio-paste/core/select";
import { fieldStyles } from "../styles/PreEngagementFormPhase.styles";
import { useDispatch, useSelector } from "react-redux";
import { createSelector } from "reselect";
import type { AppState } from "../../store/definitions";
import { updatePreEngagementData } from "../../store/actions/genericActions";
import { ThunkDispatch } from "redux-thunk";
import { ChatReducer } from "../../store/chat.reducer";
import { SessionReducer } from "../../store/session.reducer";
import { AnyAction } from "redux";
import type { WebchatConfigForm } from "@ciptex/conversation-sdk";
import { useEffect } from "react";
import { useValidation } from "../../providers/ValidationContext";

type Field = NonNullable<WebchatConfigForm['fields']>[number];

interface SelectFieldProps {
    field: Field & { type: "select" };
}

interface Option {
    label: string;
    value: string;
}

const selectFieldSelector = createSelector(
    (state: AppState) => state,
    (state) => ( {preEngagementData: state.session.preEngagementData })
);

export const SelectField = ({ field }: SelectFieldProps) => {

    const { preEngagementData } = useSelector(selectFieldSelector) || {};
    const dispatch = useDispatch<ThunkDispatch<ChatReducer | SessionReducer, AnyAction, AnyAction>>();
    const { validateField } = useValidation();


    useEffect(() => {
        if (field.required && (!preEngagementData?.[field.id] || preEngagementData[field.id] === "") && field.options?.length) {
            const initialValue = field.options[0].value;
            dispatch(updatePreEngagementData({ [field.id]: initialValue }));
            validateField(field.id, initialValue);
        }
    }, [field.id, field.required, field.options, dispatch, validateField]);

    return (
    <Box {...fieldStyles}>
        <Label htmlFor={field.id}>{field.label}</Label>
        <Select
            element="DEFAULT_SELECT"
            name={field.id}
            id={field.id}
            data-test={`pre-engagement-chat-form-${field.id}-input`}
            value={preEngagementData?.[field.id] ?? ""}
            onChange={(e) => {
                dispatch(updatePreEngagementData({ [field.id]: e.target.value }));
                validateField(field.id, e.target.value);
            }}
            autoComplete={field.id}
            required={field.required}
            >
                {field.options?.map((option : Option)=> (
                    <Option
                        key={`${field.id}-${option.value}`}
                        value={option.value}
                    >
                        {option.label}
                    </Option>
                )) ??
                <Option value="">
                    No options available
                </Option>
            }
        </Select>
    </Box>
    );
}