import React from 'react';
import { Box } from "@twilio-paste/core/box";
import { ShowIcon } from "@twilio-paste/icons/esm/ShowIcon";
import { HideIcon } from "@twilio-paste/icons/esm/HideIcon";

export const InlineToggleButton: React.FC<{
    isPreviewVisible: boolean;
    onClick: () => void;
}> = ({ isPreviewVisible, onClick }) => (
        <Box
            as="span"
            display="inline-flex"
            alignItems="center"
            marginLeft="space10"
            marginRight="space10"
            marginBottom="space10"
            cursor="pointer"
            onClick={onClick}
            style={{ verticalAlign: 'middle' }}
            title={isPreviewVisible ? "Hide link preview" : "Show link preview"}
            aria-label={isPreviewVisible ? "Hide link preview" : "Show link preview"}
        >
            {isPreviewVisible ? 
                <ShowIcon decorative={true} size="sizeIcon20"/> :
                <HideIcon decorative={true} size="sizeIcon20"/>
            }
        </Box>
);